<template>
  <div>
    <div class="pageHeader" style="overflow: hidden">
      <div style="float: left">
        <el-button
          type="primary"
          icon="el-icon-sold-out"
          @click="handle_export()"
          >导出</el-button
        >
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()"
          >刷新</el-button
        >
      </div>
      <div style="float: right">
        <el-input
          placeholder="用户姓名手机"
          v-model="keyword"
          class="input-with-select"
          style="width: 250px"
        ></el-input>
        <el-select
          v-model="status"
          placeholder="状态"
          style="margin-right: 5px"
        >
          <el-option
            v-for="item in statuses"
            :key="item"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="handle_search()" icon="el-icon-search"
          >搜索</el-button
        >
      </div>
    </div>

    <div>
      <el-table
        :data="page.list"
        v-loading="showLoading"
        element-loading-text="拼命加载中"
        border
        width="width:100%; height:50%"
      >
        <el-table-column type="index" label="顺序" width="50"></el-table-column>
        <el-table-column
          prop="username"
          label="用户名"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="用户姓名"
          width=""
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="联系方式"
          width="150"
        ></el-table-column>
        <el-table-column prop="createtime" label="加入时间" width="150">
          <template #default="scope">
            {{ computeTime(scope.row?.createtime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="100"
          :formatter="formatterStatus"
        ></el-table-column>
        <el-table-column
          prop="userResume.status"
          label="简历状态"
          width="100"
          :formatter="formatterResumeStatus"
        ></el-table-column>
        <el-table-column label="操作" conter width="280">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="handle_edit(scope.row)"
              >编辑
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handle_clickresetpassword(scope.row)"
              >重置密码
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handle_clickenable(scope.row)"
              v-if="scope.row.status == 0"
              >上线</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handle_clickdisable(scope.row)"
              v-if="scope.row.status == 1"
              >下线</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handle_clickupgradeemployer(scope.row)"
              >升级为雇主</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              @click="handle_clickdelete(scope.row)"
              >删除</el-button
            >

          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="devlogChange"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
    <!--user dialog-->
    <el-dialog
      title="用户信息"
      :close-on-click-modal="false"
      width="600px"
      v-model="digShow"
    >
      <el-form ref="form" :model="cuttData" :rules="rules">
        <el-form-item label="用户名" label-width="100px" prop="username">
          <el-input v-model="cuttData.username"></el-input>
        </el-form-item>
        <el-form-item label="用户姓名" label-width="100px" prop="name">
          <el-input v-model="cuttData.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" label-width="100px" prop="mobile">
          <el-input v-model="cuttData.mobile"></el-input>
        </el-form-item>
        <el-form-item label="微信号" label-width="100px" prop="wechatid">
          <el-input v-model="cuttData.wechatid"></el-input>
        </el-form-item>
        <el-form-item label="用户状态" label-width="100px" prop="status">
          <el-select v-model="cuttData.status" style="margin-right: 5px">
            <el-option
              v-for="item in status2"
              :key="item"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="digShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handle_validate('form')"
          :disabled="lock"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--reset password dialog-->
    <el-dialog
      title="用户重置密码"
      :close-on-click-modal="false"
      width="600px"
      v-model="resetPasswordDlgShow"
    >
      <el-form ref="form1" :model="resetPasswordData" :rules="rules1">
        <el-form-item label="用户名" label-width="100px" prop="username">
          <el-input v-model="resetPasswordData.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="100px" prop="password">
          <el-input v-model="resetPasswordData.password"></el-input>
        </el-form-item>
        <el-form-item
          label="重复密码"
          label-width="100px"
          prop="verifyPassword"
        >
          <el-input v-model="resetPasswordData.verifyPassword"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="digShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handle_resetpasswordsave('form1')"
          :disabled="lock"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import USER from "../../api/lianying/api_user";
// import EMPLOYER from '../../api/lianying/api_employer'
import { statuses, status2 } from "../../common/status.js";
import FileSaver from "file-saver";
import moment from "moment";

export default {
  name: "userlist", // 用户列表
  props: {},
  data() {
    return {
      keyword: "",
      status: undefined,

      showLoading: false,
      lock: false,
      digShow: false,
      page: { list: [], pageNum: 1, pageSize: 10, total: 0 },

      statuses: statuses,
      status2: status2,

      resetPasswordDlgShow: false,
      resetPasswordData: {
        id: undefined,
        username: "",
        password: "",
        verifyPassword: "",
      },

      // auth: {},
      cuttData: {
        id: undefined,
        name: "",
        username: "",
        password: "",
        verifyPassword: "",
        mobile: "",
        wechatid: "",
        status: undefined,
        resumeStatus: undefined,
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        verifyPassword: [
          { required: true, message: "请输入重复密码", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度为 11 个数字",
            trigger: "blur",
          },
        ],
        wechatid: [
          { required: true, message: "请输入微信号", trigger: "blur" },
          {
            min: 5,
            max: 50,
            message: "长度在 5 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
      rules1: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        verifyPassword: [
          { required: true, message: "请输入重复密码", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  computed: {
    computeTime: function () {
      return function (time) {
        return moment(time).format("YYYY-MM-DD hh:mm:ss");
      };
    },
  },
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      console.log("keyword " + this.keyword);
      USER.list({
        currentPage: this.page?.pageNum,
        pageSize: this.page?.pageSize,
        keyword: this.keyword,
      }).then((res) => {
        this.showLoading = false;
        console.log("list res " + JSON.stringify(res));
        if (1 == res.status) {
          var data = res?.data;
          this.page.total = data?.totalNumber;
          this.page.list = data?.data;
        } else {
          this.$message.error(res?.message);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    formatterStatus(row, column) {
      let status = row?.status;
      return status === 1 ? "上线" : status === 2 ? "下线" : "未知";
    },
    formatterResumeStatus(row, column) {
      let status = row?.userResume?.status;
      return status === 1 ? "上线" : status === 2 ? "下线" : "未知";
    },

    //设置导出
    handle_export(row) {
      console.log("currentPage " + this.page.pageNum);

      if (!this.page.list || this.page.list.length == 0) {
        this.$message.error("没有数据导出");
        return;
      }
      let params = {
        currentPage: 1,
        pageSize: 1000000000,
        keyword: this.keyword,
      };

      USER.exportBlob(params).then((res) => {
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = window.URL.createObjectURL(new Blob([res]));
        link.setAttribute("download", "用户列表.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.$message.success("操作成功！");
      });
    },

    handle_search() {
      this.initPageData();
    },
    handle_validate(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.hande_save();
        } else {
          return false;
        }
      });
    },
    async hande_save() {
      if (this.lock) {
        return;
      }
      this.lock = true;
      console.log("save " + JSON.stringify(this.cuttData));
      USER.save(this.cuttData).then(this.res_handle);
    },
    handle_clickresetpassword(row) {
      (this.resetPasswordDlgShow = true), (this.resetPasswordData.id = row.id);
      this.resetPasswordData.username = row.username;
    },
    handle_resetpasswordsave(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          let params = {
            password: this.resetPasswordData.password,
            verifyPassword: this.resetPasswordData.verifyPassword,
            id: this.resetPasswordData.id,
          };
          USER.resetPassword(params).then((res) => {
            this.resetPasswordDlgShow = false;
            if (res.status == 1) {
              this.$message.success("重置密码成功");
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },

    //设置修改、添加
    handle_edit(row) {
      // this.cuttData = { ...row };
      // console.log('edit user ',JSON.stringify(this.cuttData));
      // this.cuttData.resumeStatus=row?.userResume?.status
      // this.digShow = true;

      console.log("edit...");
      this.$router.push({
        path: "/user/edit",
        query: {
          id: row?.id,
        },
      });
    },
    //结果请求设置
    res_handle(res) {
      console.log("res " + JSON.stringify(res));
      this.digShow = this.lock = false;
      if (1 === res.status) {
        this.$message.success("操作成功！");
        this.init();
      } else {
        this.$message.error(res.msg);
      }
    },
    handle_clickenable(row) {
      this.$confirm("此操作将上线[" +
          row.name +
          "(" +
          row.username +
          ")] 用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          params.id = row.id;
          params.status = 1;
          USER.save(params).then(this.res_handle);
        })
        .catch(() => {});
    },
    handle_clickdisable(row) {
      this.$confirm("此操作将下线[" +
          row.name +
          "(" +
          row.username +
          ")] 用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          params.id = row.id;
          params.status = 2;
          USER.save(params).then(this.res_handle);
        })
        .catch((err) => {
          console.error("Error", err);
        });
    },
    handle_clickupgradeemployer(row) {
      this.$confirm(
        "此操作将升级用户[" +
          row.name +
          "(" +
          row.username +
          ")] 为雇主企业, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$router.push({
            path: "/user/upgradeEmployer",
            query: {
              id: row?.id,
            },
          });
        })
        .catch((err) => {
          console.error("Error", err);
        });
    },
    handle_clickdelete(row) {
      this.$confirm("此操作将删除[" +
          row.name +
          "(" +
          row.username +
          ")] 用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          params.id = row.id;
          //params.status = 2;
          USER.delete(params).then(this.res_handle);
        })
        .catch((err) => {
          console.error("Error", err);
        });
    },
  },
};
</script>

<style scoped>
.search {
  margin-bottom: 10px;
}
.search_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.search_buttons input {
  margin-right: 20px;
}
.search_buttons input:last-child {
  margin-right: 0;
}
.pageHeader {
  background-color: white;
  border-bottom: 1px solid #cccccc;
  font-size: 18px;
  padding-bottom: 5px;
  padding-top: 5px;
  /*padding: 5px 10px;*/
}

.red {
  color: #ff0000;
}
</style>